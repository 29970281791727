<template>
  <div class="mx-10 my-2">
    <h2 class="my-4 text-4xl font-semibold">Data</h2>
    <div class="pb-2 flex items-center justify-between text-gray-600 border-b">
      <div>Export data</div>
    </div>

    <div class="flex flex-row w-full mt-5">
      <button
        @click="exportUserDataLocally()"
        class="bg-primary p-2 rounded"
      >
        User Data
      </button>

      <button
        @click="exportUnitDataLocally()"
        class="bg-primary ml-2 p-2 rounded"
      >
        Unit Data
      </button>

      <!-- <button
        class="bg-primary ml-2 p-2 rounded"
        @click="exportPriceListDataLocally()"
      >
        Price List
      </button>

      <button
        class="bg-primary ml-2 p-2 rounded"
        @click="exportSalesDataLocally()"
      >
        Sales
      </button> -->
    </div>

  </div>
</template>

<script>
import firebase from "firebase";
import moment from "moment";
import reverse from "lodash/reverse";
import { parallel } from "async";
import {
  reportsCollection,
  profilesCollection,
  unitsCollection,
  dealsCollection,
} from "../../firebase.js";
import {APP_TITLE} from "@/Constants";

export default {
  data: () => ({
    title: APP_TITLE,
    reports: [],
  }),
  methods: {
    convertToCSV(objArray) {
      let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
      let str = "";

      for (let i = 0; i < array.length; i++) {
        let line = "";
        for (let index in array[i]) {
          if (line != "") line += ",";

          line += array[i][index];
        }

        str += line + "\r\n";
      }

      return str;
    },
    exportCSVFile(headers, items, fileTitle) {
      if (headers) {
        items.unshift(headers);
      }

      // Convert Object to JSON
      let jsonObject = JSON.stringify(items);

      let csv = this.convertToCSV(jsonObject);

      let exportedFilenmae = fileTitle + ".csv" || "export.csv";

      let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
        let link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          let url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilenmae);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    exportUnitDataLocally() {
      parallel(
        {
          units: (callback) => {
            unitsCollection
              .get()
              .then((querySnapshot) => {
                let units = [];

                querySnapshot.forEach((doc) => {
                  const jsonData = {
                    "Unit Status": doc.data().status || null,
                    "Unit Number": doc.data().name || null,
                    "Last Modified Time": moment(doc.data().modified.toDate())
                      .utcOffset(120)
                      .format("D MMMM YYYY - h:mm:ss a") || null,
                    "Marketing Price": doc.data().price || null,
                    "Discount Applied": doc.data().discountType || null,
                    "Parking Price": doc.data().unitParkingBay || null,
                    "Rental Guaranteed Price": doc.data().unitGuaranteedRentalPrice || null,
                    "Purchase Price": doc.data().purchasePrice || null,
                    Floor: doc.data().floor || null,
                    Type: doc.data().type || null,
                    "Internal Area": doc.data().internalArea || null,
                    "External Area": doc.data().externalArea || null,
                    "Total Area": doc.data().totalArea || null,
                    Bedrooms: doc.data().bedrooms || null,
                    Bathrooms: doc.data().bathrooms || null,
                    "Aircon": doc.data().isUnitAircon || null,
                    "Direction": doc.data().aspect || null,
                    "Outlook": doc.data().unitView || null,
                    Rates: doc.data().rates || null,
                    Levies: doc.data().levies || null,
                    "Estimated Rental": doc.data().guaranteedRental || null,
                    "Buyer First Name": doc.data().customerFirstName || null,
                    "Buyer Last Name": doc.data().customerLastName || null,
                    "Buyer Contact Number": doc.data().customerContact || null,
                    "Email": doc.data().customerEmail || null,
                    Agent: doc.data().customerAgent || null,
                  };

                  units.push(jsonData);
                });
                return callback(null, units);
              })
              .catch((err) => {
                callback(err);
              });
          },
          deals: (callback) => {
            dealsCollection
              .orderBy("modified", "desc")
              .get()
              .then((querySnapshot) => {
                let deals = [];

                querySnapshot.forEach((doc) => {
                    const jsonData = {
                      unitId: doc.data().unitId,
                      "Buyer First Name": doc.data().customerFirstName || null,
                      "Buyer Last Name": doc.data().customerLastName || null,
                      "Buyer Contact Number": doc.data().customerContact || null,
                      "Email": doc.data().customerEmail || null,
                      Agent: doc.data().customerAgent || null,
                    };
                    deals.push(jsonData);
                });
                return callback(null, deals);
              })
              .catch((err) => {
                callback(err);
              });
          },
        },
        (err, results) => {
          if (err) {
            console.log(err);
          }
          results.units?.forEach((unit) => {
            let found = results.deals?.find(
              (item) => item.unitId === unit.unitId
            );
            if (found) {
              unit["Buyer First Name"] =
                found["Buyer First Name"];
              unit["Buyer Last Name"] = found["Buyer Last Name"];
              unit["Buyer Contact Number"] = found["Buyer Contact Number"];
              unit["Email"] = found["Email"];

            }
            delete unit.unitId;
          });
          this.exportCSVFile(
            Object.keys(results.units[0]),
            results.units,
            `Units-${moment().format("DDMMyyyyHHmm")}`
          );
        }
      );
    },
    exportPriceListDataLocally() {
      unitsCollection
        .orderBy("name")
        .get()
        .then((querySnapshot) => {
          const priceLists = [];
          querySnapshot.forEach((doc) => {

            const jsonData = {
              name: doc.data().name,
              price: doc.data().price,
              purchasePrice: doc.data().purchasePrice,
            };

            priceLists.push(jsonData);

          });
          this.exportCSVFile(
            Object.keys(priceLists[0]),
            priceLists,
            `Price List-${moment().format("DDMMyyyyHHmm")}`
          );
        });
    },
    exportSalesDataLocally() {
      dealsCollection
        .orderBy('unitId')
        .get()
        .then((querySnapshot) => {
          const Sales = [];
          querySnapshot.forEach((doc) => {

            const jsonData = {
              unitId: doc.data().unitId || null,
              "User has account": doc.data().customerHasAccount || null,
              "Customer Contact Number": doc.data().customerContact || null,
              "Customer Email": doc.data().customerEmail || null,
              "Customer First Name": doc.data().customerFirstName || null,
              "Customer Last Name": doc.data().customerLastName || null,
              Agent: doc.data().customerAgent || null,
            };

            Sales.push(jsonData);

          });
          this.exportCSVFile(
            Object.keys(Sales[0]),
            Sales,
            `Sales-${moment().format("DDMMyyyyHHmm")}`
          );
        });
    },
    exportUserDataLocally() {
      console.log('Click')
      profilesCollection
        .orderBy("created")
        .get()
        .then((querySnapshot) => {
          const users = [];
          querySnapshot.forEach((doc) => {
            let shortList = [];
            doc.data().unitShortlist?.forEach((item) => {
              shortList.push(item.unitName);
            });

            let discountSurveyContactMethods = [];
            doc.data().discountSurveyContactMethods?.forEach((item) => {
              discountSurveyContactMethods.push(item);
            });

            // const discountSurveyWhyInterested = [];
            // doc.data().discountSurveyWhyInterested?.forEach((item) => {
            //   discountSurveyWhyInterested.push(item);
            // });

            const utmTags = [];
            doc.data().utmTags?.forEach((item) => {
              utmTags.push(item);
            });

            let discountSurveryTimestamp = null;

            if (!doc.data().discountSurveyTimestamp) {
              discountSurveryTimestamp = moment(discountSurveryTimestamp)
                .utcOffset(120)
                .format("D MMMM YYYY - h:mm:ss a");
            }

            const jsonData = {
              "First Name": doc.data().firstName,
              "Last Name": doc.data().lastName,
              "Contact Number": doc.data().e164ContactNumber,
              "Email": doc.data().email,
              "Created": moment(doc.data().created.toDate()).utcOffset(120).format("D MMMM YYYY - HH:MM:SS"),
              "Modified": moment(doc.data().modified.toDate()).utcOffset(120).format("D MMMM YYYY - HH:MM:SS"),
              "Role": doc.data().role,
              "Accepted Sign Up Terms": doc.data().acceptedTAC,
              "UTM Tags": utmTags.join('; '),
              "Answered Survey": doc.data().answeredDiscountSurvey,
              "Timestamp": discountSurveryTimestamp,
              "City of Residence": doc.data().discountSurveyCity || null,
              "Age": doc.data().discountSurveyAge || null,
              "Household Income": doc.data().discountSurveyHouseHoldIncome || null,
              "Job Title": doc.data().discountSurveyJobTitle || null,
              "Reason for Buying": doc.data().discountSurveyReasonBuying || null,
              // "What Attracted you to The One": doc.data().discountSurveyWhyInterested || null,
              "Preferred Contact Method": discountSurveyContactMethods.join('; ') || null,
              "Preferred Language": doc.data().discountSurveyLanguage || null,
              "Shortlist": shortList.join('; '),
            };

            users.push(jsonData);

          });
          this.exportCSVFile(
            Object.keys(users[0]),
            users,
            `Users-${moment().format("DDMMyyyyHHmm")}`
          );
        });
    },
    exportUserData(type) {
      const reportData = {
        status: "processing",
        createdAt: firebase.firestore.Timestamp.now(),
        type: type,
      };

      reportsCollection.add(reportData);
    },
    getReports() {
      reportsCollection.orderBy("createdAt").onSnapshot((snap) => {
        let reports = [];
        snap.forEach((doc) => {
          reports.push({
            created: doc.data().createdAt,
            status: doc.data().status,
            type: doc.data().type,
            url: doc.data().url,
          });
        });
        this.reports = reverse(reports);
      });
    },
  },
  created() {
    // this.getReports();
  },
};
</script>

<style>
</style>
